<template>
  <div class="page">
    <navbar index="3" sub="0" />

    <!-- banner begain -->
    <banner>
      <img src="../../assets/img/banner_pro_1.jpg" class="img-fluid" alt="">
    </banner>
    <!-- banner end -->

    <div class="main-content">

      <!-- 产品介绍 begain -->
        <div class="intro-box">
          <div class="intro-content">
            <div class="intro-img">
              <img src="../../assets/img/pro_1_1.jpg" class="img-fluid" alt="">
            </div>
            <div class="intro-txt">
              <div class="intro-title">产品介绍</div>
              <div class="intro-name">实验动物信息化管理</div>
              <div class="intro-desc">实验动物信息化管理系统是一款专业从事实验动物小鼠模型的研发、生产、销售及相关技术服务的软件产品。公司基于实验动物创制策略与基因工程遗传修饰技术，为客户提供具有自主知识产权的商品化小鼠模型，同时开展模型定制、定制繁育、功能药效分析等一站式服务，满足客户在基因功能认知、疾病机理解析、药物靶点发现、药效筛选验证等基础研究和新药开发领域的实验动物小鼠模型相关需求。</div>
            </div>
          </div>
        </div>
        <!-- 产品介绍 end -->
        
        <!-- 解决方案 begain -->
        <div class="solution-box">
          <section-title title="THE SOLUTION／解决方案 " bar-color="#154F2F"/>
          <div class="container-fluid solution-content">
            <div class="row solution-item">
              <div class="col-5 offset-1 solution-txt">
                <div class="txt-info">
                  <div class="title">
                    <h3>伦理审批</h3>
                  </div>
                  <div class="desc">
                    <p>*支持初审，进行常规错误检查，支持导出PDF.</p>
                    <p>*支持修改留痕并高亮显示</p>
                    <p>*支持多种复审模式</p>
                    <p>*指定复审专家闻值实现多个专家审核</p>
                    <p>*支持针对单个条目批阅</p>
                    <p>*支持伦理申请续期功能</p>
                    <p>*支持按专家统计审批工作量</p>
                  </div>
                </div>
              </div>
              <div class="col-5">
                <div class="solution-img">
                  <img src="../../assets/img/pro_1_2.png" class="img-fluid" alt="">
                </div>
              </div>
            </div>
            <div class="row solution-item">
              <div class="col-5 offset-1">
                <div class="solution-img">
                  <img src="../../assets/img/pro_1_3.png" class="img-fluid" alt="">
                </div>
              </div>
              <div class="col-5 solution-txt">
                <div class="txt-info">
                  <div class="title">
                    <h3>课程培训</h3>
                  </div>
                  <div class="desc">
                    <p>*支持培训流程管理</p>
                    <p>*支持培训科目管理</p>
                    <p>*支持培训课程管理</p>
                    <p>*支持培训预约审核</p>
                    <p>*支持扫码签到</p>
                    <p>*支持在线题库及测试</p>
                    <p>*短信通知开课信息</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row solution-item">
              <div class="col-5 offset-1 solution-txt">
                <div class="txt-info">
                  <div class="title">
                    <h3>动物订购</h3>
                  </div>
                  <div class="desc">
                    <p>*打通用户与供应商形式闭环</p>
                    <p>*订单直接发送至供应商</p>
                    <p>*供应商直接反馈到用户</p>
                    <p>*自动同步供应商价格</p>
                    <p>*支持自繁动物的销售</p>
                    <p>*购物车方式操作，易于，上手</p>
                    <p>*多种结算方式(系统结算、 自行结算)</p>
                    <p>*支持校内外不同价</p>
                  </div>
                </div>
              </div>
              <div class="col-5">
                <div class="solution-img">
                  <img src="../../assets/img/pro_1_4.png" class="img-fluid" alt="">
                </div>
              </div>
            </div>
            <div class="row solution-item">
              <div class="col-5 offset-1">
                <div class="solution-img">
                  <img src="../../assets/img/pro_1_5.png" class="img-fluid" alt="">
                </div>
              </div>
              <div class="col-5 solution-txt">
                <div class="txt-info">
                  <div class="title">
                    <h3>饲养繁育</h3>
                  </div>
                  <div class="desc">
                    <p>*动物异常提醒到联系人</p>
                    <p>*支持配繁、剪尾，分笼</p>
                    <p>*支持动物品系、基因型管理</p>
                    <p>*支持按房间统计</p>
                    <p>*支持按课题组统计</p>
                    <p>*支持按饲养区域汇总</p>
                    <p>*每日自动统计笼位并计费</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row solution-item">
              <div class="col-5 offset-1 solution-txt">
                <div class="txt-info">
                  <div class="title">
                    <h3>项目管理</h3>
                  </div>
                  <div class="desc">
                    <p>*支持自定义项目流程</p>
                    <p>*支持定制任务数据表单</p>
                    <p>*支持任务通知功能</p>
                    <p>*支持项目组长分配任务到组员</p>
                    <p>*支持在线提交任务数据以及数据审核</p>
                    <p>*支持项目模板，支持图方式查看项目进度</p>
                  </div>
                </div>
              </div>
              <div class="col-5">
                <div class="solution-img">
                  <img src="../../assets/img/pro_1_6.png" class="img-fluid" alt="">
                </div>
              </div>
            </div>
            <div class="row solution-item">
              <div class="col-5 offset-1">
                <div class="solution-img">
                  <img src="../../assets/img/pro_1_7.png" class="img-fluid" alt="">
                </div>
              </div>
              <div class="col-5 solution-txt">
                <div class="txt-info">
                  <div class="title">
                    <h3>产品服务管理</h3>
                  </div>
                  <div class="desc">
                    <p>*动物订购及价格管理</p>
                    <p>*饲养服务及价格管理</p>
                    <p>*技术服务及价格管理</p>
                    <p>*支持通用产品及价格管理</p>
                    <p>支持不同类别客户不同价格</p>
                    <p>*支持供应商管理</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row solution-item">
              <div class="col-5 offset-1 solution-txt">
                <div class="txt-info">
                  <div class="title">
                    <h3>财务管理</h3>
                  </div>
                  <div class="desc">
                    <p>*支持账户充值缴费</p>
                    <p>*支持直接扣款</p>
                    <p>*支持消费查询(动物费、饲养费、技术服务费等)</p>
                    <p>*可对接校财务系统(需甲方配合)</p>
                    <p>*支持账户流水查询</p>
                    <p>*支持直接扣款，扣除计费系统以外的费用</p>
                    <p>*支持账户透支额度设置</p>
                  </div>
                </div>
              </div>
              <div class="col-5">
                <div class="solution-img">
                  <img src="../../assets/img/pro_1_8.png" class="img-fluid" alt="">
                </div>
              </div>
            </div>
            <div class="row solution-item">
              <div class="col-5 offset-1">
                <div class="solution-img">
                  <img src="../../assets/img/pro_1_9.png" class="img-fluid" alt="">
                </div>
              </div>
              <div class="col-5 solution-txt">
                <div class="txt-info">
                  <div class="title">
                    <h3>设施管理</h3>
                  </div>
                  <div class="desc">
                    <p>*基础设施设置</p>
                    <p>*设置校区、建筑、楼层、区域、房间等信息</p>
                    <p>*设施权限管理，房间授权，进出规则管理</p>
                    <p>*饲养笼架管理</p>
                    <p>*设施环境检测</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 解决方案 end -->

        <!-- 产品优势 begain -->
        <div class="advant-box">
          <section-title title="PRODUCT ADVANTAGES/产品优势 " bar-color="#154F2F"/>
          <div class="container-fluid">
            <div class="row">
              <div class="col-10 offset-1 advant-content">
                <div class="advant-item">
                  <img src="../../assets/img/pro_1_11.png" class="img-fluid" alt="">
                </div>
                <div class="advant-item">
                  <img src="../../assets/img/pro_1_12.png" class="img-fluid" alt="">
                </div>
                <div class="advant-item">
                  <img src="../../assets/img/pro_1_13.png" class="img-fluid" alt="">
                </div>
                <div class="advant-item">
                  <img src="../../assets/img/pro_1_14.png" class="img-fluid" alt="">
                </div>
                <div class="advant-item">
                  <img src="../../assets/img/pro_1_15.png" class="img-fluid" alt="">
                </div>
                <div class="advant-item">
                  <img src="../../assets/img/pro_1_16.png" class="img-fluid" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 产品优势 end -->
    </div>

    <foot />
  </div>
</template>

<script>
  import navbar from '@/components/navbar'
  import banner from '@/components/banner'
  import foot from '@/components/footer'
  import sectionTitle from '@/components/sectionTitle'
  export default {
    name: 'Pro2',
    components: {
      navbar,
      banner,
      sectionTitle,
      foot
    }
  }
</script>

<style lang="scss" scoped>
  .intro-box {
    margin: 0 auto;
    padding: 60px 0;
    .intro-content {
      display: flex;
      justify-content: stretch;
      align-items: center;
      padding: 0 15%;
      font-size: 10px;
      font-weight: bold;
      color: #333;
      .intro-img {
        flex: 0 0 30%;
      }
      .intro-txt {
        flex: 1;
        margin-left: 10%;
        .intro-title {
          margin-bottom: .5em;
          font-size: 36px;
        }
        .intro-name {
          margin-bottom: .5em;
          font-size: 32px;
        }
        .intro-desc {
          line-height: 1.8;
          font-size: 24px;
          font-weight: 400;
        }
      }
    }
  }
  .solution-box {
    margin: 0 auto;
    padding: 60px 0;
    .solution-content {
      .solution-item{
        margin-top: 90px;
        .solution-txt {
          display: flex;
          justify-content: space-around;
          .txt-info {
            .title{
              margin-bottom: 30px;
              h3 {
                margin: 0;
                line-height: 1;
                font-size: 30px;
                font-weight: bold;
              }
            }
            .desc {
              p {
                margin: 0;
                line-height: 2;
                font-size: 24px;
                color: #666;
              }
            }
          }
        }
      }
      .solution-img {
        padding-top: 30px;
      }
    }
  }
  .advant-box {
    margin: 0 auto;
    padding: 60px 0;
    background: #F3F7FA url('../../assets/img/pro_1_10.png') center -10% no-repeat;
    // background-size: contain;
    .advant-content {
      margin-top: 90px;
      padding-bottom: 90px;
      display: flex;
      justify-content: space-between;
      .advant-item {
        flex: 1;
      }
    }
  }
</style>